import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Habibi, serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Habibi';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNjQUOxpQ.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
  },
  palette: {
    primary: {
      main: '#4d6aa8',  // Blue
      // light: '#757de8',
      // dark: '#002984',
    },
    secondary: {
      main: '#9b3738', // Rose
      // light: '#ff79b0',
      // dark: '#c60055',
    },
    text: {
      primary: '#262626',
    },
    background: {
      // default: '#F2EAD3',
      // cards: '#F2EAD3', 
      default: "#d9e1dd",
      cards: "#d9e1dd",
    },
  },
});

export default theme;
