import React from 'react';
import { Typography, Container, Box } from '@mui/material';

function Teaching() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" component="h1" color="primary" gutterBottom>
          Teaching
        </Typography>
        <Typography variant="body1" paragraph>
        I have taught several master classes focusing on the use of modern machine learning techniques to work with unstructured data (e.g. text, images, audio). If you’re interested in having a course like this at your institution, feel free to reach out—I’d love to chat.
        </Typography>
      </Box>
    </Container>
  );
}

export default Teaching;