// src/projectsData.js

const projectsData = [
    {
      id: 1,
      title: "Remote work detection with language models",
      imageUrl: "/images/generic.png",
      tags: ["Python", "NLP", "Transformers", "Hugging Face", "Data annotation"],
      subtitle: " ",
      description: [
        "In collaboration with Lightcast, I spearheaded the development of a specialized language model based on distilBERT to automatically detect remote work opportunities in job postings. The project involved designing a comprehensive annotation framework to generate high-quality training data and implementing a robust production pipeline for continuous prediction generation.",
        "The model now processes over one billion job postings, providing valuable insights to more than 50 researchers and supporting Lightcast's internal analytics. This tool has become instrumental in understanding the evolution of remote work trends in the job market, offering unprecedented visibility into workplace flexibility patterns across industries.",
        "Through this project, we were able to identify key indicators of remote work opportunities and track the changing landscape of workplace flexibility across different sectors and regions."
      ]
    },
    {
      id: 2,
      imageUrl: "/images/generic.png",
      title: "Recommender system for public employment",
      subtitle: "Enhancing job matching with fairness-aware recommendations for Switzerland's public employment service",
      tags: ["Recommender Systems", "Algorithmic Fairness", "R", "Python"],
      description: [
      "Developed and implemented a state-of-the-art job recommender system for Switzerland's public employment service, focusing on both performance and fairness.",
      "Enhanced user trust and system transparency by incorporating explanation mechanisms that provide clear reasoning for each job recommendation, allowing users to better understand and act on the suggestions provided."
      ]
    },
    {
      id: 3,
      imageUrl: "/images/generic.png",
      title: "Policy Reform Detection with LLMs",
      subtitle: "Automated identification of structural reforms in Latin American media",
      tags: ["LLMs", "NLP", "Public policy", "Python"],
      description: [
      "Developed an innovative Large Language Model (LLM) solution for the Inter-American Development Bank (IADB) to automatically detect and classify structural reforms across Latin American countries through news media analysis.",
      "The tool streamlines the traditionally manual process of monitoring policy changes by scanning and analyzing newspaper articles, providing the IADB with a systematic approach to track economic and social reforms across the region.",
      "This automated system enhances the bank's ability to maintain an up-to-date understanding of policy landscapes across Latin America, supporting more informed decision-making and policy research."
      ]
    },
    {
      id: 4,
      imageUrl: "/images/generic.png",
      title: "Automated Audio Transcription Pipeline",
      subtitle: "Implementing state-of-the-art transcription models for market research.",
      tags: ["Python", "WhisperAI", "Hugging Face", "Audio Processing"],
      description: [
      "Designed and implemented a cutting-edge automated transcription system at NODO Research, leveraging WhisperAI's powerful speech recognition capabilities through Hugging Face's infrastructure. The solution transformed the traditional manual transcription workflow into a streamlined, automated process.",
      "The project delivered remarkable efficiency gains, achieving a 95% reduction in transcription processing time while simultaneously cutting operational costs by 20%. This breakthrough in automation not only accelerated the transcription workflow but also maintained high accuracy standards.",
      "The system was deployed as a demonstration on Hugging Face's platform, showcasing its practical applications and establishing a new benchmark for audio transcription automation in research environments."
      ]
      },
    {
        id: 5,
        imageUrl: "/images/generic.png",
        title: "LLMs in Economics: A Handbook Chapter",
        subtitle: "Contributing to the academic discourse on AI applications in economics",
        tags: ["LLMs", "Economics", "Research"],
        description: [
        "Co-authored a comprehensive chapter for the Palgrave Handbook of Economics and Language, focusing on the emerging role of large language models in economic research and applications.",
        "The work explores the intersection of cutting-edge AI technology and economic analysis, providing a scholarly framework for understanding how LLMs are transforming economic research methodologies and applications."
        ]
        },
    {
        id: 6,
        imageUrl: "/images/generic.png",
        title: "Talk to my CV",
        subtitle: "An AI-powered chatbot that brings my resume to life",
        tags: ["Python", "LangChain", "LLMs", "Chatbot", "Dash"],
        description: [
          "Developed an interactive chatbot that dynamically answers questions based exclusively on my CV content, creating a unique and engaging way for recruiters and hiring managers to explore my professional background.",
          "Leveraged LangChain's document loading and retrieval capabilities to create a context-aware conversation system that maintains accuracy and relevance while responding to queries about my experience, skills, and qualifications."
          ]
    },
    {
            id: 7,
            imageUrl: "/images/generic.png",
            title: "Income Inequality Interactive Visualizer",
            subtitle: "Making economic concepts tangible through interactive data exploration",
            tags: ["Data Visualization", "Economics", "Dash", "Python"],
            description: [
            "Developed an interactive educational tool for Universidad de los Andes that transforms complex household income data into intuitive visualizations, allowing students to explore income inequality concepts hands-on.",
            "The platform enables users to simulate and visualize the distributional effects of various economic shocks on household income, making abstract economic concepts more concrete and accessible for undergraduate students.",
            "Successfully enhanced classroom engagement by providing students with a practical, interactive way to understand income distribution dynamics and inequality measures through direct manipulation of data."
            ]
    },
    {
      id: 8,
      imageUrl: "/images/generic.png",
      title: "Movie2Vec: Neural Movie Recommendations",
      subtitle: "Adapting word embedding techniques for personalized movie suggestions",
      tags: ["Python", "Word2Vec", "Recommender Systems", "MovieLens"],
      description: [
      "Developed an innovative movie recommendation system by adapting the Word2Vec neural network architecture, traditionally used for natural language processing, to understand and predict movie preferences based on user viewing patterns.",
      "Leveraging the extensive MovieLens dataset, the system learns to represent movies in a multi-dimensional space where similar movies cluster together, enabling intuitive and accurate recommendations based on viewing history."
      ]
      }

  ];
  
  export default projectsData;