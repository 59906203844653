import React from 'react';
import { Container, Grid, Card, CardActionArea, CardContent, CardMedia, Typography, Chip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import projectsData from '../projectsData';

function Projects() {
    return (
        <Container maxWidth="md" sx={{ py: 8 }}>
          <Typography variant="h4" component="h1" color="primary" align="center" sx={{ mb: 4 }} gutterBottom>
            Selected projects
          </Typography>
          <Grid container spacing={4}>
            {projectsData.map((project) => (
              <Grid item key={project.id} xs={12} sm={6} md={4}>
                <Card sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  border: '3px solid', 
                  borderColor: 'text.primary', 
                  backgroundColor: 'background.cards'
                }}>
                  <CardActionArea component={Link} to={`/projects/${project.id}`}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={project.imageUrl}
                      alt={project.title}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="h2" sx={{ mb: 4, lineHeight: 1.5 }}>
                        {project.title}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                        {project.tags.map((tag, index) => (
                          <Chip 
                            key={index} 
                            label={tag} 
                            size="small" 
                            sx={{ 
                              backgroundColor: 'primary.main', 
                              color: 'primary.contrastText',
                              '&:hover': {
                                backgroundColor: 'primary.dark',
                              },
                            }} 
                          />
                        ))}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
    );
}

export default Projects;