import React from 'react';
import { Typography, Container, Box, Link} from '@mui/material';
import AnimatedText from './AnimatedText';

function Home() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, bgcolor: "background.default"}}>
        <Typography variant="h4" align="left" component="h1" color="primary" sx={{ mb: 0.5 }}> 
          yabra muvdi
        </Typography>
        <Typography variant="h6" align="left" gutterBottom sx={{ mb: 6 }}>
        <AnimatedText />
        </Typography>
        <Typography variant="body1" paragraph>
        I design and develop solutions using machine learning, natural language processing, and causal inference. With a strong research background, I have a deep understanding of the foundations and limitations of these methods. I excel at translating complex technical concepts into clear, accessible language for diverse audiences, including consultancy clients and students.
        </Typography>
        <Typography variant="body1" paragraph>
            Want to know more about me?
          </Typography>
        <Typography variant="body1" paragraph>
            {' '}
            <Link 
                href="https://yabramuvdi.github.io/files/CV.pdf" 
                color="secondary" 
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
            >
              CV 📜
            </Link>
            {' '} <Box component="span" sx={{ mx: 1 }} /> {' '}
            <Link
                href="https://yabra-cv-chatbot-5d4c332eae4a.herokuapp.com/"
                color="secondary"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
            >
             Chatbot 🤖
            </Link>
            {' '}
            </Typography>
      </Box>
    </Container>
  );
}

export default Home;