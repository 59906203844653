import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';
import projectsData from '../projectsData';

function ProjectDetail() {
  const { id } = useParams();
  const project = projectsData.find(p => p.id === parseInt(id));

  if (!project) {
    return <Typography>Project not found</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, bgcolor: "background.default", p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {project.title}
        </Typography>
        <Typography variant="body" component="h2" gutterBottom color="text.secondary" sx={{ mb: 4}}>
          {project.subtitle}
        </Typography>
        {project.description.map((paragraph, index) => (
          <Typography key={index} variant="body1" paragraph>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </Container>
  );
}

export default ProjectDetail;