import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { keyframes } from '@emotion/react';

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; transform: translateY(10px); }
  20%, 80% { opacity: 1; transform: translateY(0); }
`;

const words = ['economist', 'data scientist', 'teacher', 'researcher', 'consultant', 'student'];

function AnimatedText() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500); // Change word every 3 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ display: 'inline-block', position: 'relative', height: '1.5em', width: '200px' }}>
      {words.map((word, i) => (
        <Typography
          key={word}
          variant="h6"
          component="span"
          color="secondary"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            animation: `${fadeInOut} 3s ease-in-out infinite`,
            opacity: 0,
            display: i === index ? 'inline-block' : 'none',
          }}
        >
          {word}
        </Typography>
      ))}
    </Box>
  );
}

export default AnimatedText;